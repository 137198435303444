<template>
    <div style="background-color: #fff">
        <div class="pt-3">
            <search @condition="condition" :fields="searchFields">
                <el-button @click="exportData" v-access="{url:baseUrl,method:'POST'}" size="medium" slot="add"
                           icon="el-icon-download" type="primary">导出
                </el-button>
            </search>
        </div>
        <div class="pay-sum">
            <div :class="[item.type]" class="sum-item" v-for="item in $store.state.dict.pay_status" :key="item.value">
                <!--                <div class="icon">-->
                <!--                </div>-->
                <div class="title">
                    {{ item.label }}
                </div>
                <div class="sum">
                    <countTo :startVal='0' :endVal="sum[item.value]||0" :duration='1000'></countTo>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <my-table :data="tableData" :fields="showFields">
                <el-table-column slot="operation" fixed="right" width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button
                            v-if="scope.row.is_payed===1"
                            size="mini"
                            @click="addRefund(scope.$index)" v-access="{url:baseRefundUrl,method:'POST'}"
                            icon="el-icon-warning-outline"></el-button>
                    </template>
                </el-table-column>
            </my-table>
        </div>
        <div class="pb-3 mt-3">
            <el-pagination
                @size-change="sizeChange"
                @current-change="pageChange"
                :current-page="page.pageNumber"
                :page-sizes="[10, 15, 30, 50]"
                :page-size="page.pageSize"
                :background="true"
                layout="sizes,prev, pager,next,total"
                :total="page.total">
            </el-pagination>
        </div>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>
<script>
import F from '@/fields/fields';
import * as XLSX from 'xlsx';
import search from "@/components/common/search";
import MyTable from "@/components/common/my-table";
import countTo from "vue-count-to";
import {formatTime, reStoreKeyValue} from "@/utils/tools";

export default {
    name: "appSms",
    components: {
        search, MyTable, countTo
    },
    created() {

    },
    mounted() {
    },
    data: function () {
        return {
            baseUrl: '/manage/bill/index',
            baseSumUrl: '/manage/bill/sum',
            baseRefundUrl:'/bigmap/pay/refund',
            page: {
                search: {},
                sort: {},
                total: 1,
                pageSize: 15,
                pageNumber: 1,
            },
            sum: {},
            subscribe: [],
            userGroup: [],
            tableData: [],
            form: {
                action_name: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            }
        }
    },
    computed: {
        searchFields: function () {
            return F.search('payList', 'search');
        },
        showFields: function () {
            return F.show('payList', 'show');
        },
    },
    methods: {
        exportData() {
            //导出数据，一次最多导出2W条
            let params = JSON.parse(JSON.stringify(this.page));
            params.pageNumber = 1;
            params.pageSize = 20000;
            params.type = 'export';
            this.$axios.get(this.baseUrl, {
                params,
            }).then(data => {
                console.log(data);
                let field = F.get('payList', 'export');
                // console.log(field);
                let excelData = data.data.data.map(v => {
                    let row = {};
                    field.fields.map(vv => {
                        switch (vv.type) {
                            case 'dict_key':
                            case 'dict_tag':
                                row[vv.title] = reStoreKeyValue(vv.dict_key, v[vv.name]);
                                break;
                            case 'daterange':
                                row[vv.title] = formatTime(v[vv.name], 'Y-m-d H:i:s');
                                break;
                            default:
                                row[vv.title] = v[vv.name];
                        }
                    });
                    return row;
                });
                let ws = XLSX.utils.json_to_sheet(
                    excelData,
                    {
                        header: field.fields.map(v => v.title) // 表头
                    }
                );
                let fileName = '购买列表';
                let blob = this.sheet2blob(ws, fileName);
                const url = URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url;
                link.target='_blank';
                link.download = `${fileName}.xlsx`
                document.body.appendChild(link)
                link.click();
                document.body.removeChild(link);
            });
        },
        sheet2blob(sheet, sheetName) {
            sheetName = sheetName || 'sheet1'
            let workbook = {
                SheetNames: [sheetName],
                Sheets: {}
            }
            workbook.Sheets[sheetName] = sheet // 生成excel的配置项

            let wopts = {
                bookType: 'xlsx', // 要生成的文件类型
                bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
                type: 'binary'
            }
            let wbout = XLSX.write(workbook, wopts)
            let blob = new Blob([s2ab(wbout)], {
                type: "application/octet-stream"
            })

            function s2ab(s) {
                let buf = new ArrayBuffer(s.length)
                let view = new Uint8Array(buf)
                for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
                return buf
            }

            return blob
        },
        condition: function (search) {
            this.page.search = search;
            this.page.pageNumber = 1;
            this.search();
        },
        search: function () {
            this.$axios.get(this.baseUrl, {
                params: this.page,
            }).then(data => {
                this.page.total = data.data.total;
                this.page.pageNumber = data.data.pageNumber;
                this.page.pageSize = data.data.pageSize;
                this.tableData = data.data.data;
                this.getSum();
            });
        },
        getSum() {
            this.$axios.get(this.baseSumUrl, {
                params: this.page,
            }).then(data => {
                let sum = {};
                data.data.map(v => {
                    sum[v.is_payed] = v['sum'] || 0;
                });
                this.$set(this, 'sum', sum);
            });
        },
        sortChange: function (column) {
            if (!column.order) return;
            this.page.sort = {prop: column.prop, order: column.order};
            this.search();
        },
        sizeChange: function (size) {
            this.page.pageSize = size;
            this.search();
        },
        pageChange: function (page) {
            this.page.pageNumber = page;
            this.search();
        },
        filterPlatform(value, row) {
            return row.tag === value;
        },
        submit: function (data) {
            // this.con(data);
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }
            switch (this.actionName) {
                case 'addRefund':
                    this.$app.post(this.baseRefundUrl, data.data).then(adData => {
                        this.$message.success('申请成功，等待审核');
                        this.form.show = false;
                        this.tableData.splice(this.index, 1, adData.data);
                    });
                    break;
                case 'editAd':
                    this.$axios.put(this.baseUrl, data.data).then(adData => {
                        this.$message.success('保存成功!');
                        this.form.show = false;
                        this.tableData.splice(this.index, 1, adData.data);
                    });
                    break;
                case 'addServer':
                    this.$axios.post(this.baseUrl, data.data).then(userData => {
                        this.page.total++;
                        this.form.show = false;
                        this.$message.success('创建成功!');
                        this.tableData.unshift(userData.data);
                    });
                    break;
            }
        },
        addRefund(index){
            this.tableData[index].refund_price=this.tableData[index].pay_price;
            let form = F.get('payListRefund', 'add_refund', this.tableData[index]);
            this.actionName = 'addRefund';
            this.form = form;
            this.index = index;
        },
        editAd: function (index) {
            let form = F.get('appAd', 'edit', this.tableData[index]);
            this.actionName = 'editAd';
            this.form = form;
            this.index = index;
        },
        addServer: function () {
            let form = F.get('appAd', 'add');
            this.actionName = 'addServer';
            this.form = form;
        },
        deleteServer: function (index, id) {
            this.$confirm('你真的要删除这个订阅吗?', '操作确认').then(() => {
                this.$axios.delete(this.baseUrl, {data: {id}}).then(() => {
                    this.tableData.splice(index, 1);
                    this.page.total--;
                    this.$message.success('删除成功!');
                });
            });
        }
    }
}
</script>

<style lang="less" scoped>
.pay-sum {
    display: flex;

    .sum-item {
        flex: 1;
        margin: 10px;
        display: flex;
        height: 50px;
        border-radius: 5px;
        color: #fff;
        background-color: #45bbe0;

        &.danger {
            background-color: rgba(122, 54, 60, 0.85);
        }

        .title {
            flex-shrink: 0;
            width: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }

        .sum {
            display: flex;
            align-items: center;
            height: 100%;
            font-weight: bold;
            justify-content: center;
            font-size: 18px;
        }
    }
}
</style>
